@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* styles.css */
.scrollbar-hide::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body {
  background: rgba(247, 248, 252, 0.5);
  padding: 0 !important;
  margin: 0 !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-button-prev {
  margin-left: -10px;
}

.swiper-button-next {
  margin-right: -10px;
}

.sample-slider [class^="swiper-button-"]::after {
  font-size: 34px;
  color: #00b1f4 !important;
}

.studentList {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(0, 177, 244, 0.31);
}

*:focus {
  outline: none;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-arrow {
  -moz-appearance: textfield;
}

.css-13cymwt-control {
  border: 1px solid #00b1f4 !important;
}

.css-qbdosj-Input {
  padding: 3px !important;
}

/* WebKit Scrollbar Styles */
/* Track */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b1f4; /* Change the color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00b1f4; /* Change the color of the scrollbar handle on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background: #e0e0e0; /* Color of the scrollbar track on hover */
}
